import { atom } from "recoil"

export const userWallet = atom({
    key: "userWallet",
    default: {
        isConnected: false,
        walletAddress: '',
    }
})

export const atomTokenPairList = atom({
    key: "tokenPairList",
    default: []
})

export const atomTokenLandingList = atom({
    key: "tokenLandingList",
    default: []
})

export const atomTargetTokenList = atom({
    key: "targetTokenList",
    default: []
})

export const atomTokenPriceList = atom({
    key: "tokenPriceList",
    default: []
})


export const atomTokenBalanceList = atom({
    key: "tokenBalanceList",
    default: []
})

export const atomTokenOrderList = atom({
    key: "tokenOrderList",
    default: []
})

export const atomTokenTradeList = atom({
    key: "tokenTradeList",
    default: []
})

export const atomTradingViewData = atom({
    key: "tradingViewData",
    default: []
})

export const atomWalletTokenBalance = atom({
    key: "walletTokenBalance",
    default: []
})

export const atomTokenPairBalance = atom({
    key: "tokenPairBalance",
    default: []
})

export const atomWalletCaladexPairBalance = atom({
    key: "walletCaladexPairBalance",
    default: []
})

/**
 * caladex balances of wallet for certain ticker
 */
export const atomWalletCaladexBalance = atom({
    key: "walletCaladexBalance",
    default: []
})

/**
 * fetch farming pools
 */
export const atomFarmingPoolList = atom({
    key: "farmingPoolList",
    default: []
})